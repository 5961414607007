<template>
  <b-container>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      spinner-type="grow"
      spinner-variant="primary"
    > 
      <FilterComponent
        :store="store" 
        :filter="filter"
        @filter-now="filter = '';changePage(1);" 
        @change-page="changePage($event)"
        @add-new="add()"
        @filter-word="filter = $event"
      />

      <CrudComponent
        :store.sync="store"
        :filter="filter"
        :fetch="fetchItems"
        :formerr.sync="formError"
        @editNow="edit"
        @showNow="show"
        @delNow="del"
        @changeNow="changePage"
      />
    </b-overlay>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import router from '@/router';
import FilterComponent from '@/components/FilterComponent';
import CrudComponent from '@/components/CrudComponent';

export default {
  name: 'Tariffs',
  components:{
    FilterComponent,
    CrudComponent
  },
  data() {
    return {
      isBusy: false,
      filter: '',
    }
  },
  computed: {
    ...mapState("tariff", ['formError']),
    ...mapGetters("tariff", ['store'])
  },
  watch: {
    formError(newVal){
      if(!newVal){
        this.$bvModal.hide('modal');
      }
    }
  },
  methods: {
    ...mapActions("tariff", [
      "fetchItems", 
      "addItem", 
      "deleteItem", 
      "updateItem", 
      "changePage"
    ]),
    
    // 1. Prepare modal for creation of item.
    add() {
      router.push('biller');
    },

    // Prepare model for updation.
    edit() {
      alert('Disabled');
    },

    // 2. Validation after creating form.
    validateState(name) {
      const { $dirty, $error } = this.$v.form.item[name];
      return $dirty ? !$error : null;
    },

    // 3. Handle Submit - Call Store fundtion.
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if(this.form.mode == 'update'){
        this.updateItem(this.form.item);
      }else{
        this.addItem(this.form.item);
      }
    },

    // 4. Show Item details in another Modal.
    show(item) {
      router.push(`tariffs/${item.id}`);
    },

    // 5. Delete the item.
    del(id) {
      let res = window.confirm("Are you sure?");
      if(res) this.deleteItem(id);
    },
  }
}
</script>
